import router from '@/router'
import store from '@/store'
import Page404 from "@/views/404"

/**
 * 路由加载组件
 * @param view
 * @returns {function(*=): any}
 */
export function loadComponent(view) {
    return resolve => require([`@/views/${view}`], resolve).catch(_err => router.push('/404'))
}

/**
 * 根据树形菜单构造动态路由
 * @param mainLayout
 * @param menuList
 * @returns
 */
export function buildDynamicRoutes(mainLayout, menuList) {
    //打开新窗口的菜单
    let newPageList = []
    //按钮权限列表
    let permissions = []
    menuList.forEach(item => {
        let path = '/' + item.code
        let route = buildRoute(item, path, [item.name])
        item.path = path
        if (item.openNewPage) {
            //打开新窗口的路由
            router.addRoute(route)
            newPageList.push(path)
        } else {
            mainLayout.children.push(route)
        }
        //递归二级以下菜单
        if (Array.isArray(item.children) && item.children.length) {
            let loopObj = {permissions, newPageList, breadcrumb: route.meta.breadcrumb}
            buildSubMenu(route, item.children, loopObj)
        }
        if (route.children.length) {
            route.redirect = route.children[0].path
        }
    })
    if (mainLayout.children.length) {
        mainLayout.redirect = mainLayout.children[0].path
        mainLayout.children.push({
            path: '/404',
            name: 'Page404',
            component: loadComponent('404'),
            meta: {title: '404'}
        })
    }
    store.commit('setNewPageList', newPageList)
    store.commit('setPermissions', permissions)
    return mainLayout
}

/**
 * 递归二级以下菜单
 * @param parentMenu
 * @param children
 * @param permissions
 * @param newPageList
 */
function buildSubMenu(parentMenu, children, loopObj) {
    let {permissions, newPageList, breadcrumb} = loopObj
    children.forEach(item => {
        if (item.menuType === '2') {
            //按钮权限
            permissions.push(item.code)
        }
        let route = parentMenu
        let newBreadcrumb = [...breadcrumb, item.name]
        if (item.url && item.code) {
            let path = parentMenu.path + '/' + item.code
            //面包屑从父节点迭代
            let route = buildRoute(item, path, newBreadcrumb)
            item.path = path
            if (item.openNewPage) {
                //打开新窗口的路由
                router.addRoute(route)
                newPageList.push(path)
            } else {
                parentMenu.children.push(route)
            }
        }

        if (Array.isArray(item.children) && item.children.length) {
            loopObj.breadcrumb = newBreadcrumb
            buildSubMenu(route, item.children, loopObj)
        }
    })
}

function buildRoute(menu, path, breadcrumb) {
    return {
        path,
        name: menu.code,
        component: loadComponent(menu.url),
        children: [],
        meta: {
            title: menu.name,
            breadcrumb: breadcrumb
        }
    }
}
