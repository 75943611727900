const install = function (Vue) {
    Vue.directive('hasPermission', {
        bind(el, binding, vnode) {
            const {value} = binding
            if (value) {
                let hasPermission = false
                const permissions = vnode.context.$store.getters.permissions
                hasPermission = judgePermission(value, permissions)
                if (!hasPermission) {
                    el.parentNode && el.parentNode.removeChild(el)
                }
            } else {
                throw new Error('请设置操作权限标签值')
            }
        }
    })

    /**
     * 判断是否有功能权限
     * @param val
     * @returns {*}
     */
    Vue.prototype.hasPermission = function (val) {
        if (val) {
            const permissions = this.$store.getters.permissions
            return judgePermission(val, permissions)
        }
        return false
    }

    function judgePermission(val, permissions) {
        if (typeof val == 'string') {
            return permissions.includes(val)
        } else if (val instanceof Array) {
            return val.some(permissionCode => {
                return permissions.includes(permissionCode)
            })
        } else {
            throw new Error('权限标签值为String或Array')
        }
    }
}


if (window.Vue) {
    window['hasPermission'] = hasPermission
    Vue.use(install)
}

export default install
