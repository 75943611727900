<template>
  <el-empty description="请求异常">
    <el-button type="primary" @click.native="gotoMain">返回页面</el-button>
  </el-empty>
</template>

<script>
export default {
  name: "Error",
  methods: {
    gotoMain() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep(.el-empty__description p) {
  font-size: 14px;
  color: #5e6d82;
}
</style>
