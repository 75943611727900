import CryptoJS from 'crypto-js'

//在线加密解密：http://tool.chacuo.net/cryptaes
const aesKey = 'Q57g0uZOet1IsRCF'

//AES加密
export function encryptAes(data) {
    const key = CryptoJS.enc.Utf8.parse(aesKey)
    const encrypt = CryptoJS.AES.encrypt(data, key, {
        iv: CryptoJS.enc.Utf8.parse(aesKey),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypt.toString()
}

//AES解密
export function decryptAes(data) {
    const key = CryptoJS.enc.Utf8.parse(aesKey)
    const decrypt = CryptoJS.AES.decrypt(data, key, {
        iv: CryptoJS.enc.Utf8.parse(aesKey),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return decrypt.toString(CryptoJS.enc.Utf8)
}
