const getters = {
    userInfo: state => state.user.userInfo,
    sidebarCollapse: state => state.menu.sidebarCollapse,
    isMenuReady: state => state.menu.isMenuReady,
    menuList: state => state.menu.menuList,
    newPageList: state => state.menu.newPageList,
    permissions: state => state.menu.permissions,
    visitedViews: state => state.tags.visitedViews,
    cachedViews: state => state.tags.cachedViews,
    prevActiveTopMenu: state => state.tags.prevActiveTopMenu
}

export default getters
