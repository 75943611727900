<template>
  <div class="layout-out">
    <template v-if="menuList.length">
      <el-container style="height: 100%">
        <el-header height="50px" class="header">
          <div class="header-bar">
            <div class="header-logo">
              <i class="el-icon-help" style="font-size: 28px;padding-right:5px;"></i>智慧出行大数据平台
            </div>
            <div class="header-menu">
              <div class="left-arrow" @click="scollMenu(-1)" v-show="isMenuScoll">
                <i class="el-icon-arrow-left"></i>
              </div>
              <el-menu id="menuUl" mode="horizontal" :default-active="activeMenu" class="menu-custom"
                @select="changeMenu">
                <el-menu-item v-for="(item, index) in menuList" :index="item.path" :key="index">
                  <div class="menu-item">
                    <svg-icon :icon-class="item.icon" class-name="menu-icon" v-if="item.icon" />
                    {{ item.name }}
                  </div>
                </el-menu-item>
              </el-menu>
              <div class="left-arrow" @click="scollMenu(1)" v-show="isMenuScoll">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="header-user">
              <el-dropdown @command="handleDropdown">
                <div class="user-info">
                  <i class="el-icon-user-solid"></i>
                  <div class="user-account">{{ userInfo.account }}</div>
                  <i class="el-icon-caret-bottom"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item ><a href="https://fqzfenypmdi.feishu.cn/share/base/form/shrcnrKlfzhHY8zMXZClZAvRRHg" target="_blank">{{ '意见反馈' }}</a> </el-dropdown-item>
                  <el-dropdown-item ><a href="https://fqzfenypmdi.feishu.cn/wiki/TuLGwmmTCir8ImkSrDPcU7B9nQc?from=from_copylink" target="_blank">{{ '操作文档' }}</a> </el-dropdown-item>
                  <el-dropdown-item command="changePwd">{{ $t('login.changePwd') }}</el-dropdown-item>
                  <el-dropdown-item command="logout">{{ $t('login.exitSystem') }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="entrance">
                <svg-icon icon-class="operation" class-name="entrance-icon"  @click="getLargeScreen"/>
              </div>
            <div class="header-tools">
              <i class="el-icon-full-screen" @click="fullScreen"></i>
            </div>
          </div>
        </el-header>
        <el-main style="padding: 0">
          <router-view></router-view>
        </el-main>
      </el-container>
    </template>
    <div class="system-tips" v-else>
      <el-empty :description="$t('login.emptyMenu')">
        <el-button type="primary" @click.native="reload">{{ $t('operation.refresh') }}</el-button>
        <el-button type="primary" @click.native="logout">{{ $t('login.exitSystem') }}</el-button>
      </el-empty>
    </div>
    <change-pwd v-model="showChangePwdDialog"></change-pwd>
    <detail-dialog v-model="detailObj.show" :id="detailObj.id"></detail-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import screenfull from 'screenfull'
import { logout } from '@/api/security'
import { logoutHandle } from '@/utils/auth'
import ChangePwd from './change_password'
// 引入公告详情弹窗
import DetailDialog from '@/components/sys-notice-detail/index'
import { USER_SYS_NOTICE, findByUserPage} from '@/api/system'

export default {
  name: "Layout",
  components: { ChangePwd, DetailDialog},
  created() {
    if (this.menuList.length) {
      this.$nextTick(() => {
        this.initTopMenus()
        this.handleUserNotice()
      })
    }
    this.setActiveMenu()
    // 监听页面窗口变化
    window.addEventListener('resize', this.initTopMenus)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.initTopMenus)
  },
  watch: {
    '$route'(_to, _from) {
      this.setActiveMenu()
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'menuList', 'newPageList'])
  },
  data() {
    return {
      showChangePwdDialog: false,
      isMenuScoll: false,
      eveyMenuWidth: 200,
      left: 0,
      activeMenu: '',
      detailObj: {
        show: false,
        id: ''
      },
    }
  },
  methods: {
    initTopMenus() {
      let scrollWidth = document.getElementById('menuUl').scrollWidth
      let offsetWidth = document.getElementById('menuUl').offsetWidth
      this.isMenuScoll = scrollWidth > offsetWidth
    },
    //设置菜单高亮
    setActiveMenu() {
      this.activeMenu = '/' + this.$route.path.split('/')[1]
    },
    changeMenu(index) {
      let currentActiveMenu = this.activeMenu
      if (this.newPageList.includes(index)) {
        //打开新页面时，高亮会变位，将头部菜单高亮归位
        this.activeMenu = index
        setTimeout(() => {
          this.activeMenu = currentActiveMenu
        }, 50)
        window.open(index, '_blank')
      } else {
        this.$router.push(index)
      }
    },
    //菜单移动按钮
    scollMenu(factor) {
      let scrollLeft = document.getElementById('menuUl').scrollLeft
      scrollLeft = scrollLeft + this.eveyMenuWidth * factor
      document.getElementById('menuUl').scrollLeft = scrollLeft > 0 ? scrollLeft : 0
    },
    // 调整到信息中心
    getLargeScreen(){
      window.open('/DataAnalysis/LargeScreen', '_blank')
    },
    //全屏切换
    fullScreen() {
      screenfull.toggle()
    },
    //刷新页面
    reload() {
      this.$router.go(0)
    },
    //退出登录
    logout() {
      logout().then(_res => {
        logoutHandle()
      }).catch(_err => {
        logoutHandle()
      })
    },
    handleDropdown(command) {
      switch (command) {
        case 'changePwd':
          this.showChangePwdDialog = true
          break
        case 'logout':
          this.$confirm(this.$t('login.exitTip'), this.$t('common.tips'), {
            confirmButtonText: this.$t('common.confirmTip'),
            cancelButtonText: this.$t('common.cancelTip'),
            type: 'warning'
          }).then(() => {
            this.logout()
          }).catch(() => {
          })
          break
      }
    },
    //获取用户系统公告
    handleUserNotice(){
      let data ={
        enabled: '1',
        page :0,
        size: 1
      }
      findByUserPage(USER_SYS_NOTICE, data).then(res => {
        if (res.code === 0) {
          if(res.data.content.length > 0){
            let id = res.data.content[0].entityId
            this.detailObj = {show: true,id: id}
          }
        }
      }).catch(_err => {
        this.$message.error(_err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/header.scss";
</style>
