import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {findUserMenuTree} from '@/api/system'
import {buildDynamicRoutes} from '@/utils/menu'

Vue.use(VueRouter)

//防止点击相同菜单造成重复路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: 'history',
    base: '/saas-rental/',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return {x: 0, y: 0}
    }
})

const whiteList = ['/login']
const errorList = ['/error']
router.beforeEach((to, from, next) => {
    let userInfo = store.getters.userInfo
    NProgress.start()
    if (userInfo != null && userInfo.userId) {
        if (errorList.indexOf(to.path) !== -1) {
            next()
        } else {
            if (!store.getters.isMenuReady) {
                //加载动态菜单
                loadMenus(to, next)
            } else {
                // 已登录且要跳转的页面是登录页
                if (to.path === '/login') {
                    next('/')
                } else {
                    next()
                }
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1 || errorList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            // 否则全部重定向到登录页
            let path = to.path === '/' ? '/login' : `/login?redirect=${to.fullPath}`
            next(path)
        }
    }
})

export const loadMenus = (to, next) => {
    findUserMenuTree().then(res => {
        if (res.code === 0) {
            store.commit('setIsMenuReady', true)
            let mainLayout = {
                path: '/',
                name: 'layout',
                component: () => import('@/views/layout/index'),
                children: []
            }
            let mainMenu = res.data || []
            mainMenu = mainMenu.filter(item => !item.parentId || !item.code || !item.url || item.enabled !== '1')
            if (mainMenu.length === 0) {
                router.addRoute(mainLayout)
                next({path: '/'})
            } else {
                store.commit('setMenuList', mainMenu)
                //加载动态路由
                let mainRoute = buildDynamicRoutes(mainLayout, mainMenu)
                router.addRoute(mainRoute)
                router.addRoute({path: '/:catchAll(.*)', redirect: '/404'})
                next({...to})
            }
        }
    }).catch(_err => {
        NProgress.done()
        next({path: '/error'})
    })
}

router.afterEach(() => {
    NProgress.done()
})

export default router
