import request from '@/api/request'

export const USER_LOGIN_LOG = 'userLoginLog'

/**
 * 登录(带验证码)
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function login(data) {
    return request.post('/api/v1/auth/login', data)
}

/**
 * 弱口令登录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function weakLogin(data) {
    return request.post('/api/v1/auth/weakLogin', data)
}

/**
 * 短信验证码登录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function smsLogin(data) {
    return request.post('/api/v1/auth/smsLogin', data)
}

/**
 * 登出
 * @returns {Promise<AxiosResponse<any>>}
 */
export function logout() {
    return request.get('/api/v1/auth/logout')
}

/**
 * 获取验证码
 * @returns {Promise<AxiosResponse<any>>}
 */
export function requestCaptcha() {
    return request.get('/api/v1/auth/captcha')
}

/**
 * 发送短信验证码
 * @returns {Promise<AxiosResponse<any>>}
 */
export function requestSmsCaptcha(data) {
    return request.post('/api/v1/auth/sendSmsCaptcha', data)
}

/**
 * 修改密码
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function changePassword(data) {
    return request.post('/api/v1/base/user/changePwd', data)
}

/**
 * 分页查询用户登录日志
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findUserLoginLogByPage(data) {
    return request.post(`api/v1/auth/${USER_LOGIN_LOG}/byPage`, data)
}

/**
 * 删除用户登录日志
 * @param userId
 * @param loginTime
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteUserLoginLog(userId, loginTime) {
    return request.delete(`api/v1/auth/${USER_LOGIN_LOG}/${userId}/${loginTime}`)
}
