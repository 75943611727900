const lang = {
    login: {
        welcomeLogin: 'Welcome Login',
        cnLang: 'Chinese',
        enLang: 'English',
        contactUs: 'Contact Us',
        accountLogin: 'Account Login',
        smsLogin: 'SMS Login',
        accountPlaceholder: 'Username',
        pwdPlaceholder: 'Password',
        phonePlaceholder: 'Phone No.',
        correctPhoneTip: 'Please input correct phone No.',
        captchaPlaceholder: 'Captcha',
        rememberPwd: 'Remember Password',
        sliderCheckTip: 'Drag Slider to Verify and Login',
        sliderCheckSuccess: 'Verify Success',
        changePwd: 'Change Password',
        exitSystem: 'Exit',
        exitTip: 'Are you sure to exit system?',
        emptyMenu: 'Please refresh again after configuring the role',
        accountValidTip: 'Please input account',
        pwdValidTip: 'Please input password',
        phoneValidTip: 'Please input phone No.',
        captchaValidTip: 'Please input captcha',
        login: 'Login',
        smsCaptcha: 'Captcha',
        getCaptcha: 'Get Captcha',
        loginSuccess: 'Login success',
        safetyMode: 'Safety Mode'
    },

    changePwd: {
        oldPwd: 'Initial Password',
        newPwd: 'New Password',
        confirmPwd: 'Confirm Password',
        oldPwdValidTip: 'Please input initial password',
        newPwdValidTip: 'Please input new password',
        confirmPwdValidTip: 'Please input confirm password',
        pwdValidTip: 'The password entered twice is inconsistent',
        loginAgainTip: 'Update succeeded. Please log out and log in again.'
    },

    tags: {
        reload: 'Reload',
        closeCurrTab: 'Close CurrentTab',
        closeOtherTabs: 'Close OtherTabs',
        closeAllTabs: 'Close AllTabs'
    },

    operation: {
        refresh: 'Refresh',
        export: 'Export',
        batchImport: 'Import',
        batchDelete: 'Delete',
        add: 'Add',
        edit: 'Edit',
        del: 'Delete',
        detail: 'Detail',
        search: 'Search',
        reset: 'Reset',
        save: 'Save',
        stockOut: 'StockOut',
        batchStockOut: 'BatchStockOut',
        submit: 'Submit',
        cancel: 'Cancel',
        cmd: 'Cmd'
    },

    common: {
        tips: 'Tips',
        order: 'Order',
        operation: 'Operation',
        createTime: 'Create Time',
        createBy: 'Create By',
        updateTime: 'Update Time',
        updateBy: 'Update By',
        remark: 'Remark',
        enableState: 'Enable State',
        enableColumn: 'Enable',
        organizationId: 'Company ID',
        organization: 'Company',
        rentalSite: 'Rental Site',
        rentalStore: 'Rental Store',
        warehouse: 'Warehouse',
        setmeal: 'Setmeal',
        selectTip: 'Please select',
        addTip: 'Click right button to add',
        selectDate: 'Select Date',
        inputIconNameTip: 'Please input icon name',
        orgValidTip: 'Please select company',
        rentalSiteValidTip: 'Please select site',
        enableValidTip: 'Please select enable state',
        regionValidTip: 'Please select region',
        selectOrgFirstTip: 'Please select company first',
        noPermissionTip: 'No query permission',
        exportSuccess: 'Export success',
        exportFail: 'Export fail',
        importSuccess: 'Import success',
        addSuccess: 'Add success',
        updateSuccess: 'Update success',
        saveSuccess: 'Save success',
        deleteSuccess: 'Delete success',
        cancelSuccess: 'Cancel success',
        operateSuccess: 'Operate Success',
        deleteTip: 'Are you sure to delete this?',
        cancelConfirm: 'Are you sure to cancel this?',
        processConfirm: 'Are you sure to process this?',
        sendCmdTip: 'Are you sure to send this command?',
        confirmTip: 'Yes',
        cancelTip: 'No',
        yes: 'Yes',
        no: 'No',
        enable: 'Enable',
        disable: 'Disable',
        associatePhotos: 'Associate Photos',
        more: 'More',
        fold: 'Fold',
        deviceNum: 'Device No.',
        deviceNumValidTip: 'Please input device No.',
        checkboxTip: 'Please check the data',
        checkOrgEqualTip: 'Please check the data of the same company',
        selectedNum: 'Number of selected',
        inventoryStatus: 'Inventory Status',
        lng: 'Longitude',
        lat: 'Latitude',
        region: 'Region',
        address: 'Address',
        addressValidTip: 'Please input address',
        addressEmptyTip: 'Address cannot be empty',
        managerNameValidTip: 'Please input manager name',
        managerPhoneValidTip: 'Please input manager phone',
        loginTime: 'Login Time',
        logoutTime: 'Logout Time',
        onlineDuration: 'Duration',
        loginIp: 'IP',
        loginType: 'Login Type',
        imsi: 'IMSI',
        hardVsn: 'Hardware Vsn',
        softVsn: 'Software Vsn',
        protocolVsn: 'Protocol Vsn',
        alarmCategory: 'Alarm Category',
        alarmType: 'Alarm Name',
        operationTime: 'Operation Time',
        operator: 'Operator',
        operatorPhone: 'Operator Phone',
        orderNumValidTip: 'Please input sorted number',
        cnMoneyUnit: '¥',
        dayUnit: 'Days',
        secondUnit: 'Seconds',
        hourUnit: 'Hours',
        minuteUnit: 'Minutes',
        speedUnit: 'km/h',
        mileageUnit: 'KM',
        lenUnit: 'digit',
        validFrom: 'Valid From',
        validTo: 'Valid To',
        to: '-',
        rentalType: 'Setmeal Type',
        selectFile: 'Select File',
        selectFileTip: 'Please select file',
        process: 'Process',
        complete: 'Complete',
        file: 'File',
        reason: 'Reason',
        qrcode: 'QrCode',
        download: 'Download',
        deleted: 'Deleted',
        operationType: 'Operation Type',
        operationLog: 'Operation Log',
        batteryList: 'Battery List',
        cabinetList: 'Cabinet List',
        vehicleList: 'Vehicle List',
        RentalUserList: 'Rental User List',
        batterySwapRecordList: 'Battery Swap Record List',
        batteryOrderList: 'Battery Order List',
        fundFlowList:'Fund Flow List',
        partnerTransaction: 'Partner Transaction',
        sendSuccess: 'Send Success',
        today: 'Today',
        yesterday: 'Yesterday',
        aWeekAgo: 'A week ago',
        createStartTime: 'Create start',
        createEndTime: 'Create end',
        success: 'Success',
        fail: 'Fail',
        startTime: 'Start Time',
        siteGroup: 'Site Group',
        auditStatus: 'Audit Status',
        auditTime: 'Audit Time',
        auditUserName: 'Audit User',
        detail: 'Detail',
        failedReason: 'Fail Reason',
        audit: 'Audit',
        partner: 'Partner',
        personal: 'Personal',
        uploadPhoto: 'Upload Photo',
        price: 'Price'
    },

    map: {
        selectPoint: 'Map pickup point',
        poiSearch: 'POI Search',
        poiPlaceholder: 'Please input keyword',
        selectPointConfirm: 'Set this point as address?',
        lngValidTip: 'Please input longitude',
        latValidTip: 'Please input latitude'
    },

    organization: {
        addTitle: 'Add Company',
        editTitle: 'Edit Company',
        detailTitle: 'Company Info',
        name: 'Name',
        orgType: 'Company Type',
        parent: 'Parent',
        contacts: 'Contact',
        phone: 'Phone',
        companyName: 'company name',
        legalPerson: 'Legal Person',
        businessLicense: 'Business License',
        businessScope: 'Business Scope',
        nameValidTip: 'Please input name',
        orgTypeValidTip: 'Please select company type',
        parentValidTip: 'Please select parent company'
    },

    role: {
        addTitle: 'Add Role',
        editTitle: 'Edit Role',
        detailTitle: 'Role Info',
        name: 'Name',
        permission: 'Permissions',
        nameValidTip: 'Please input name'
    },

    user: {
        addTitle: 'Add User',
        editTitle: 'Edit User',
        detailTitle: 'User Info',
        keyword: 'Name/Account/Phone/Email',
        selectRoleTip: 'Please select role',
        username: 'User Name',
        account: 'Account',
        password: 'Password',
        roles: 'Roles',
        phone: 'Phone',
        email: 'Email',
        expireDate: 'Expire Date',
        orgPermissions: 'Org Permission',
        sitePermissions: 'Site Permission',
        pwdTips: '6-16 digits and letters (case sensitive)',
        orgPermissionTips: 'Default is the user\'s organization and subordinate range',
        sitePermissionTips: 'Default is the user\'s organization and subordinate range',
        usernameValidTip: 'Please input username',
        accountValidTip: 'Please input account',
        emailValidTip: 'Incorrect email format',
        pwdValidTip: 'Please input password',
        incorrectPwdTip: 'Incorrect password format',
        phoneNoValidTip: 'Please input phone No.',
        viewedSensitive: 'View sensitive data'
    },

    menu: {
        addTitle: 'Add Menu',
        editTitle: 'Edit Menu',
        keyword: 'Name/Code',
        parent: 'Parent Menu',
        menuType: 'Menu Type',
        name: 'Menu name',
        code: 'Code',
        url: 'URL',
        icon: 'Icon',
        orderNum: 'Sorted By',
        openNewPage: 'Open New Page',
        selectIconTip: 'Select Icon',
        nameValidTip: 'Please input name',
        codeValidTip: 'Please input code',
        menuTypeValidTip: 'Please select menu type'
    },

    dict: {
        addTitle: 'Add Dict',
        editTitle: 'Edit Dict',
        selectCategoryTip: 'Select category',
        keyword: 'Name/Code',
        name: 'Chinese Name',
        nameEn: 'English Name',
        code: 'Code',
        orderNum: 'Sorted By',
        parent: 'Parent Category',
        nameValidTip: 'Please input chinese name',
        codeValidTip: 'Please input code'
    },

    operationLog: {
        logDesc: 'Log Type',
        requestIp: 'IP',
        browserName: 'Browser',
        osName: 'OS',
        costTime: 'Cost Time',
        location: 'Location',
        requestMethod: 'Request Method',
        requestParams: 'Request Params',
        content: 'Operation Content'
    },

    userLoginLog: {
        loginResult: 'Login Result',
        browserName: 'Browser',
        osName: 'OS',
        loginLocation: 'Location'
    },

    unregisterTerminal: {
        cabinetTitle: 'Unregister Cabinet',
        batteryTitle: 'Unregister Battery',
        terminalNum: 'Device No.',
        receiveTime: 'Receive Time',
        nodeName: 'Gateway Name',
        protocolType: 'Protocol Type'
    },

    batteryModel: {
        addTitle: 'Add Battery Model',
        editTitle: 'Edit Battery Model',
        detailTitle: 'Battery Model Info',
        brand: 'Brand',
        modelCode: 'Model',
        batteryType: 'Battery Type',
        ratedVoltage: 'Rated Voltage',
        ratedCapacity: 'Rated Capacity',
        packSize: 'Pack Size',
        packWeight: 'Pack Weight',
        batteryCnt: 'Battery Count',
        bmsBrand: 'BMS Brand',
        bmsModel: 'BMS Model',
        bmsVsn: 'BMS Version',
        brandValidTip: 'Please input brand',
        batteryTypeValidTip: 'Please select battery type',
        ratedVoltageValidTip: 'Please input rated voltage',
        ratedCapacityValidTip: 'Please input rated capacity',
        modelCodeTip: 'Voltage and capacity combination',
        packSizeTip: 'mm*mm*mm',
        keyword: 'Brand/Model',
        bundledSetmeal: 'Bundled Setmeal',
        fullyMileage: 'Fully Mileage'
    },

    cabinetModel: {
        addTitle: 'Add Cabinet Model',
        editTitle: 'Edit Cabinet Model',
        detailTitle: 'Cabinet Model Info',
        brand: 'Brand',
        modelCode: 'Model',
        cabinetType: 'Cabinet Type',
        cabinetSize: 'Cabinet Size',
        spaceCnt: 'Space Count',
        spaceSize: 'Space Size',
        screenSize: 'Screen Size',
        screenUnit: 'Inch',
        protectionLevel: 'Protection Level',
        brandValidTip: 'Please input brand',
        modelCodeValidTip: 'Please input model',
        spaceCntValidTip: 'Please input space count',
        cabinetTypeValidTip: 'Please select cabinet type',
        cabinetSizeTip: 'mm*mm*mm',
        spaceSizeTip: 'mm*mm*mm',
        keyword: 'Brand/Model'
    },

    vehicleModel: {
        addTitle: 'Add Vehicle Model',
        editTitle: 'Edit Vehicle Model',
        detailTitle: 'Vehicle Model Info',
        brand: 'Brand',
        modelCode: 'Model',
        vehicleSize: 'Vehicle Size',
        maxSpeed: 'Max Speed',
        weight: 'Weight',
        wheelSize: 'Wheel Size',
        brandValidTip: 'Please input brand',
        modelCodeValidTip: 'Please input model',
        keyword: 'Brand/Model'
    },

    warehouse: {
        addTitle: 'Add Warehouse',
        editTitle: 'Edit Warehouse',
        detailTitle: 'Warehouse Info',
        warehouseName: 'Warehouse Name',
        managerName: 'Manager Name',
        managerPhone: 'Manager Phone',
        warehouseNameValidTip: 'Please input warehouse name',
        keyword: 'Warehouse/Manager'
    },

    battery: {
        addTitle: 'Add Battery',
        editTitle: 'Edit Battery',
        detailTitle: 'Battery Info',
        brand: 'Battery Brand',
        modelCode: 'Battery Model',
        batteryModelValidTip: 'Please select battery model',
        rentalSiteValidTip: 'Please select site',
        keyword: 'Battery Name/No./BMS',
        batteryNameTip: 'Default is the battery No.',
        deviceNumValidTip: 'Please input device No.',
        batteryName: 'Battery Name',
        batteryNum: 'Battery No.',
        bmsNum: 'BMS No.',
        rfidNum: 'RFID No.',
        selectTip: 'Please select battery',
        siteGroup: 'Site Group',
        batteryCycle: 'Cycle Times'
    },

    cabinet: {
        addTitle: 'Add Cabinet',
        editTitle: 'Edit Cabinet',
        detailTitle: 'Cabinet Info',
        changeBusiness: 'Change Business',
        cabinetName: 'Cabinet Name',
        cabinetNum: 'Cabinet No.',
        brand: 'Cabinet Brand',
        modelCode: 'Cabinet Model',
        cabinetNumValidTip: 'Please input cabinet No.',
        cabinetModelValidTip: 'Please select cabinet model',
        rentalSiteValidTip: 'Please select site',
        cabinetNameTip: 'Default is the cabinet No.',
        keyword: 'Cabinet Name/No.',
        doorId: 'Door No.',
        selectTip: 'Please select cabinet',
        siteGroup: 'Site Group',
        batteryCnt: 'Battery Count',
        enableTakeBattery: 'Take Battery',
        enableExchangeBattery: 'Exchange Battery',
        enableReturnBattery: 'Return Battery',
        showLocation: 'Show Location',
        businessDiy: 'Business DIY',
        cascadeCabinetNo: 'Cascade Cabinet No.',
        cascadeCabinetList: 'Cascade Cabinet List'
    },

    vehicle: {
        addTitle: 'Add Vehicle',
        editTitle: 'Edit Vehicle',
        detailTitle: 'Vehicle Info',
        vehicleName: 'Vehicle Name',
        vehicleNum: 'VIN',
        brand: 'Vehicle Brand',
        modelCode: 'Vehicle Model',
        tboxNum: 'Tbox Num',
        tboxProvider: 'Tbox Provider',
        siteGroup: 'Site Group',
        keyword: 'Name/VIN/TboxNo/PlateNo',
        rentalSiteValidTip: 'Please select site',
        vehicleNameTip: 'Default is the VIN',
        deviceNumValidTip: 'Please input VIN',
        vehicleModelValidTip: 'Please select vehicle model',
        selectTip: 'Please select vehicle',
        vehicleStore: 'Vehicle store'
    },

    terminal: {
        onlineStatus: 'Online Status',
        positionStatus: 'Position Status',
        monitorStatus: 'Monitor Status',
        track: 'Track',
        cmd: 'Command',
        deviceName: 'Device Name',
        deviceNum: 'Device No.',
        terminalCategory: 'Device Category',
        brand: 'Brand',
        modelCode: 'Model Code',
        keyword: 'Device Name/Device No.'
    },

    batteryStatus: {
        batteryStatus: 'Battery Status',
        ctrlStatus: 'Ctrl Status',
        batteryBusiness: 'Business Status',
        soc: 'SOC',
        collectionTime: 'Collection Time'
    },

    cabinetStatus: {
        cabinetStatus: 'Cabinet Status',
        collectionTime: 'Collection Time',
        protocolVsn: 'Protocol Vsn',
        hardVsn: 'Hard Vsn',
        softVsn: 'Soft Vsn',
        imei: 'IMEI',
        ccid: 'CCID',
        imsi: 'IMSI'
    },

    siteGroup: {
        addTitle: 'Add Site Group',
        editTitle: 'Edit Site Group',
        detailTitle: 'Site Group Info',
        groupName: 'Group Name',
        groupNameValidTip: 'Please input group name',
        keyword: 'Group Name'
    },

    rentalSite: {
        addTitle: 'Add Site',
        editTitle: 'Edit Site',
        detailTitle: 'Site Info',
        siteGroup: 'Site Group',
        siteName: 'Site Name',
        stationType: 'Station Type',
        businessTime: 'Business Time',
        cameras: 'Cameras',
        storeFiles: 'Store',
        licenseFiles: 'License',
        idCardFiles: 'ID Card',
        siteNameValidTip: 'Please input site name',
        stationTypeValidTip: 'Please select station type',
        keyword: 'Site Name/Manager',
        managerName: 'Manager Name',
        managerPhone: 'Manager Phone',
        swapCabinetCnt: 'Swap Cabinet Count',
        chargeCabinetCnt: 'Charge Cabinet Count',
        batteryCnt: 'Battery Count',
        vehicleCnt: 'Vehicle Count'
    },

    cameraChannel: {
        editTitle: 'Edit Video Channel',
        channelName: 'Channel Name',
        channelNameValidTip: 'Please input channel name'
    },

    alarm: {
        startTime: 'Start Time',
        endTime: 'End Time',
        duration: 'Alarm Duration'
    },

    stock: {
        stockInTime: 'Stock-In Time',
        stockOutTime: 'Stock-Out Time'
    },

    cabinetLoginLog: {
        iccid: 'ICCID',
        imei: 'IMEI'
    },

    cmdLog: {
        cmdType: 'Cmd Type',
        responseTime: 'Response Time',
        sendResult: 'Send result',
        userType: 'User Type',
        paramSendDesc: 'Param Send'
    },

    setmeal: {
        addTitle: 'Add Setmeal',
        editTitle: 'Edit Setmeal',
        detailTitle: 'Setmeal Info',
        setmealListed: 'Setmeal listing',
        setmealRemoval: 'Setmeal removal',
        setmealNameValidTip: 'Please input setmeal name',
        billingTypeValidTip: 'Please select billing type',
        rentAmountValidTip: 'Please input rent amount',
        discountedRentValidTip: 'Please input discounted rent',
        depositAmountValidTip: 'Please input deposit amount',
        lossProtFeeValidTip: 'Please input loss prot fee',
        discountFactorValidTip: 'Please input discount factor',
        billingCycleValidTip: 'Please input billing cycle',
        timesValidTip: 'Please input times',
        vehicleModelValidTip: 'Please select vehicle model',
        singleExchangePriceValidTip: 'Please input single exchange price',
        keyword: 'Setmeal Name',
        setmealStatus: 'Setmeal status',
        setmealName: 'Setmeal Name',
        billingType: 'Billing Type',
        time: 'Time',
        count: 'Count',
        rentalBattery: 'Rent Battery',
        rentAmount: 'Rent Amount',
        discountedRent: 'Discounted Rent',
        depositAmount: 'Deposit Amount',
        lossProtFee: 'Loss Prot Fee',
        discountFactor: 'Discount Factor',
        billingCycle: 'Billing Cycle',
        times: 'Times',
        singleExchangePrice: 'Exchange Price',
        sitePermissionTips: 'Default is all sites',
        orderNum: 'Sorted By',
        purchaseCount: 'Purchase Quantity',
        setmealRemark: 'Setmeal Remark'
    },
}
export default lang
