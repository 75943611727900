import store from '@/store'
import router from '@/router'

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
    //清除用户登录信息
    store.commit('removeUserInfo')
    //清除权限菜单信息
    store.commit('removeMenus')
}

/**
 * 登录失效处理
 * @param redirect 是否需要带重定向地址
 */
export function logoutHandle(redirect = false) {
    //清除登录信息
    clearLoginInfo()

    if (redirect) {
        let currentRoute = router.currentRoute
        let fullPath = currentRoute.fullPath
        if (fullPath === '/') {
            router.replace('/login')
        } else {
            let redirectPath = fullPath.includes('redirect') ? currentRoute.path : fullPath
            router.replace({
                path: '/login',
                query: {
                    redirect: redirectPath
                }
            })
        }
    } else {
        router.replace('/login')
    }
    location.reload()
}
