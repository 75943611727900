/**
 * 是否外链
 * @param path
 * @returns {boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 密码由数字、字母（区分大小写）组成，6-16位字符
 * @param value
 * @returns {boolean}
 */
export function isPassword(value) {
    return /^[0-9A-Za-z]{6,16}$/.test(value)
}

/**
 * 判断是否为邮箱
 * @param value
 * @returns {boolean}
 */
export function isEmail(value) {
    return /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(value)
}

/**
 * 判断是否大于等于0的整数
 * @param value
 * @returns {boolean}
 */
export function isPositiveInteger(value) {
    value = value.toString().trim()
    return /^\d+$/.test(value)
}
