<template>
  <div>
    <el-dialog width="500px" :close-on-click-modal="false" :visible.sync="show" @close="closeDialog">
      <div slot="title">
        <span class="dialog-title">{{ $t('login.safetyMode') }}</span>
      </div>
      <el-form :model="formData" ref="form" :rules="rules" label-width="120px">
        <el-form-item prop="phone" :label="$t('user.phone')">
          <el-input v-model.trim="formData.phoneNum" readonly size="medium"></el-input>
        </el-form-item>
        <el-form-item prop="captcha" :label="$t('login.smsCaptcha')">
          <el-input v-model.trim="formData.captcha" size="medium">
            <div slot="suffix">
              <el-button type="primary" size="mini" :loading="captchaBtnLoading" :disabled="captchaBtnDisabled" @click="getSmsCaptcha">{{ captchaBtnTxt }}</el-button>
            </div>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" :loading="loading" @click="handleSmsLogin">{{ $t('operation.submit') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {requestSmsCaptcha, smsLogin} from '@/api/security'
import {LoginType} from "@/utils/constant"

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    phone: {
      required: true,
      type: String
    },
    routePath: {
      required: true,
      type: String
    }
  },
  watch: {
    value(bool) {
      this.show = bool
      if (bool) {
        this.formData.phoneNum = this.phone.replace(this.phone.substring(3, 7), "****")
      }
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      formData: {
        phoneNum: '',
        captcha: ''
      },
      rules: {
        captcha: {required: true, message: this.$t('login.captchaValidTip'), trigger: 'blur'}
      },
      captchaBtnLoading: false,
      captchaBtnDisabled: false,
      captchaBtnTxt: this.$t('login.getCaptcha'),
      smsCaptchaTime: 0
    }
  },
  methods: {
    closeDialog() {
      this.$emit('input', false)
      this.loading = false
    },

    getSmsCaptcha() {
      let params = {
        phone: this.phone
      }
      this.captchaBtnLoading = true
      requestSmsCaptcha(params).then(res => {
        this.captchaBtnLoading = false
        if (res.data === true) {
          this.smsCaptchaTime = 60
          this.captchaBtnDisabled = true
          this.changeSmsCaptchaBtn()
        }
      }).catch(_err => {
        this.captchaBtnLoading = false
      })
    },

    //验证码倒计时
    changeSmsCaptchaBtn() {
      if (this.smsCaptchaTime > 0) {
        this.smsCaptchaTime--
        this.captchaBtnTxt = this.smsCaptchaTime + "秒后获取"
        setTimeout(this.changeSmsCaptchaBtn, 1000)
      } else {
        this.smsCaptchaTime = 0
        this.captchaBtnTxt = this.$t('login.getCaptcha')
        this.captchaBtnDisabled = false
      }
    },

    handleSmsLogin() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }

        let params = {
          phone: this.phone,
          captcha: this.formData.captcha,
          loginType: LoginType.BROWSER
        }
        this.loading = true
        smsLogin(params).then(res => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success(this.$t('login.loginSuccess'))
            this.$store.commit('setUserInfo', res.data)
            this.$router.replace(this.routePath).catch(_err => {
            })
            this.closeDialog()
          }
        }).catch(_err => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
